import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AlertComponent } from '../../shared/core/alert/alert.component';

const PATH_PRIVATE = '/plataforma';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private snackbar: MatSnackBar, private router: Router) {}

  show(
    type: 'error' | 'success' | 'info' = 'success',
    message: string,
    duration = 5000
  ) {
    let mode = this.router.url.includes(PATH_PRIVATE) ? 'private' : 'public';

    this.snackbar.openFromComponent(AlertComponent, {
      data: { type, message },
      duration,
      verticalPosition: 'top',
      panelClass: ['snackbar', `snack-bg-${type}`, `snack-${mode}`]
    });
  }
}
